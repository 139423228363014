<template>
  <div>
    <div id="FcRenderer" class="vx-row" v-if="setData" @contextmenu.prevent>
      <!--- LEARN APP START --->
      <div class="FcAppScreen w-3/4 flex items-center justify-center overflow-hidden">
        <div class="FcAppParent">
          <div class="FcAppContainer">
            <vx-card class="mb-5 hidden set-name-mobile">
              <div class="flex items-center justify-between">
                <div>
                  <h1 class="font-bold set-name-text-mobile" style="margin-bottom: 2px">{{ setData.name }}</h1>
                  <p class="font-light set-name-terms-mobile">
                    {{ activeTerms.length }} Total Terms <span v-if="filterSelected !== null">(Filtered)</span>
                  </p>
                </div>
                <img
                  :src="setData.thumbnail"
                  v-if="setData.thumbnail"
                  width="75"
                  height="75"
                  class="rounded shadow-md cursor-pointer"
                  draggable="false"
                  alt=""
                />
              </div>
            </vx-card>

            <div class="mobile-navigation">
              <div @click="previousTerm" @mousedown="startPreviousTerm" @mouseup="stopAction" @mouseleave="stopAction">
                <feather-icon
                  icon="ArrowLeftIcon"
                  svgClasses="h-32 w-32"
                  :class="setTermIndex <= 0 ? 'opacity-20' : ' fcTheArrow cursor-pointer'"
                />
              </div>

              <div class="fcCard">
                <slide-y-down-transition :duration="200">
                  <div class="mb-8" v-if="showCard">
                    <flash-card-view
                      v-if="!enableEditMode"
                      :ref="'flashCardView'"
                      :key="setTermIndex"
                      :text-front="currentTerm.term"
                      :text-back="currentTerm.definition"
                      :img-front="currentTerm.term_image"
                      :img-back="currentTerm.definition_image"
                      :flagged-term="progressData.termsFlagged.includes(currentTerm.id)"
                      :lottie-animation="settings.lottieAnimation"
                      :default-side="settings.defaultCardSide"
                      :card-color="settings.defaultCardColor"
                      @swipe-left="previousTerm"
                      @swipe-right="nextTerm"
                      v-on:flipped="flippedCard"
                    >
                    </flash-card-view>
                    <flash-card-admin
                      v-if="enableEditMode"
                      :key="setTermIndex"
                      :flagged-term="progressData.termsFlagged.includes(currentTerm.id)"
                      :term-data="currentTerm"
                      v-on:flipped="flippedCard"
                    >
                    </flash-card-admin>
                  </div>
                </slide-y-down-transition>

                <div class="flex items-center term-nav-container">
                  <vs-button
                    color="primary"
                    type="gradient"
                    icon-pack="feather"
                    icon="icon-fast-forward"
                    class="font-bold text-sm"
                    @click="jumpTermPopup = !jumpTermPopup"
                    >Jump
                  </vs-button>
                  <p class="font-light text-center term-count">{{ setTermIndex + 1 }} / {{ activeTerms.length }}</p>
                  <vs-button
                    type="gradient"
                    icon-pack="feather"
                    icon="icon-flag"
                    class="font-bold text-sm"
                    :color="progressData.termsFlagged.includes(currentTerm.id) ? 'success' : 'danger'"
                    @click="toggleFlagged(currentTerm.id)"
                    id="flagBtn"
                  >
                    {{ progressData.termsFlagged.includes(currentTerm.id) ? 'Unflag' : 'Flag' }}
                  </vs-button>
                </div>
              </div>
              <div @click="nextTerm" @mousedown="startNextTerm" @mouseup="stopAction" @mouseleave="stopAction">
                <feather-icon
                  icon="ArrowRightIcon"
                  svgClasses="h-32 w-32"
                  :class="setTermIndex + 1 >= activeTerms.length ? 'opacity-0 ' : 'fcTheArrow cursor-pointer'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- TOP INFO START --->
      <div class="vx-col w-1/4 mb-3 info-container">
        <vx-card class="mb-5 set-name-desktop">
          <div class="flex items-center justify-between">
            <div>
              <h1 class="font-bold" style="margin-bottom: 2px">{{ setData.name }}</h1>
              <p class="font-light">{{ activeTerms.length }} Total Terms <span v-if="filterSelected !== null">(Filtered)</span></p>
            </div>
            <img
              :src="setData.thumbnail"
              v-if="setData.thumbnail"
              width="75"
              height="75"
              class="rounded shadow-md cursor-pointer"
              draggable="false"
              alt=""
            />
          </div>
        </vx-card>

        <div class="bottom-content">
          <vx-card class="mb-5 set-progress-card" v-if="filterSelected === null">
            <!---<div class="flex justify-between items-center mb-3">
              <h2 class="font-bold">Set Progress</h2>
              <h2 class="font-light">{{ progressSummary.percent }}%</h2>
            </div>--->

            <div class="flex flex-row justify-between">
              <div class="flex flex-col justify-center">
                <div class="flex items-center mb-2">
                  <h2 class="font-bold hidden progress-text-medium">{{ setData.name }} Progress</h2>
                  <h2 class="font-bold progress-text-desktop">Set Progress</h2>
                </div>
                <div class="flex items-center mb-2">
                  <feather-icon icon="CheckCircleIcon" svgClasses="h-6 w-6 mr-2 text-success" />
                  <h5 class="font-bold mr-2">Completed:</h5>
                  <h5 class="font-light">{{ progressSummary.termsCompleted }}</h5>
                </div>
                <div class="flex items-center mb-2">
                  <feather-icon icon="XCircleIcon" svgClasses="h-6 w-6 mr-2 text-danger" />
                  <h5 class="font-bold mr-2">Remaining:</h5>
                  <h5 class="font-light">{{ progressSummary.termsRemaining }}</h5>
                </div>
                <div class="flex items-center">
                  <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 mr-2 text-warning" />
                  <h5 class="font-bold mr-2">Flagged:</h5>
                  <h5 class="font-light">{{ progressSummary.termsFlagged }}</h5>
                </div>
              </div>
              <div class="flex justify-end items-center">
                <radial-progress-bar
                  :diameter="115"
                  :completed-steps="progressSummary.termsCompleted"
                  :total-steps="progressSummary.termsCount"
                  :animateSpeed="1100"
                  timingFunc="ease"
                  innerStrokeColor="#181d2a"
                  startColor="#31B952"
                  stopColor="#0cdc62"
                  :strokeWidth="7"
                  :innerStrokeWidth="7"
                >
                  <h2 class="font-light">{{ progressSummary.percent }}%</h2>
                </radial-progress-bar>
              </div>
            </div>
          </vx-card>

          <vx-card class="mb-5">
            <div class="flex justify-between items-center mb-3">
              <h2 class="font-bold">Options</h2>
              <div class="flex items-center justify-start align-middle mt-1" v-if="$acl.check('proctor')">
                <p class="mr-2 font-light">Admin Mode</p>
                <vs-switch v-model="enableEditMode" color="success"></vs-switch>
              </div>
            </div>
            <div class="flex items-center mb-3">
              <feather-icon icon="FilterIcon" svgClasses="h-6 w-6 mr-2 text-primary" />
              <h5 class="font-bold">
                Current Filter
                <span class="font-light">
                  - {{ filterSelected !== null ? filterSelected.charAt(0).toUpperCase() + filterSelected.slice(1) : 'Not Enabled' }}</span
                >
              </h5>
            </div>

            <div class="grid grid-cols-2 gap-3">
              <vs-button
                color="primary"
                type="gradient"
                icon-pack="feather"
                icon="icon-filter"
                class="w-full font-bold text-sm"
                @click="filterPopup = !filterPopup"
                >Filter Terms
              </vs-button>
              <vs-button
                color="success"
                type="gradient"
                icon-pack="feather"
                icon="icon-search"
                class="w-full font-bold text-sm"
                @click="searchTermPopup = !searchTermPopup"
                >Search Terms
              </vs-button>
              <vs-button
                color="warning"
                type="gradient"
                icon-pack="feather"
                icon="icon-settings"
                class="w-full font-bold text-sm"
                @click="settingsPopup = !settingsPopup"
                >Settings
              </vs-button>
              <vs-button
                color="danger"
                type="gradient"
                icon-pack="feather"
                icon="icon-arrow-left"
                class="w-full font-bold text-sm"
                @click="$router.push('/flashcards/select')"
                >Exit Set
              </vs-button>
            </div>
          </vx-card>
        </div>

        <vx-card>
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-7 w-7 mr-2" />
            <h6 class="ml-2">In Order To Complete A Set You Must Go Through Each Term Without Skipping</h6>
          </div>
        </vx-card>
      </div>

      <!--- POPUP MODALS --->
      <vs-popup :active.sync="jumpTermPopup" title="Jump To Term">
        <div class="p-2 popup-input">
          <div class="flex items-center mb-4">
            <feather-icon icon="InfoIcon" svgClasses="h-8 w-8 mr-1" />
            <h3 class="ml-1">Enter A Term Number Between 1 and {{ activeTerms.length }}</h3>
          </div>

          <div class="mb-5">
            <vs-input
              v-model="jumpTermValue"
              class="w-full"
              placeholder="Term Number"
              v-validate="`between:1,${activeTerms.length}`"
              name="Jump"
            />
            <span class="text-danger text-sm" v-show="errors.has('Jump')">{{ errors.first('Jump') }}</span>
          </div>

          <vs-button
            color="primary"
            type="gradient"
            icon-pack="feather"
            icon="icon-fast-forward"
            :disabled="!jumpTermValue || errors.has('Jump')"
            @click="jumpToTermByNumber"
            >Jump To Term
          </vs-button>
        </div>
      </vs-popup>
      <vs-popup :active.sync="filterPopup" class="pb-3" title="Set Filter">
        <div class="p-3">
          <!-- <vx-card class="mb-3">
            <div class="term-filter-container">
              <div class="flex flex-row gap-x-3">
                <div class="vx-col flex items-center justify-center">
                  <feather-icon :icon="'CheckCircleIcon'" svg-classes="h-12 w-12 text-success" />
                </div>
                <div class="vx-col flex flex-col justify-center">
                  <h4 class="mb-1 font-semibold">Completed Terms</h4>
                  <h6 class="font-light">{{ progressSummary.termsCompleted }} Terms</h6>
                </div>
              </div>

              <div class="term-filter-button vx-col p-0" v-if="progressSummary.termsCompleted > 0">
                <vs-button
                  class="shadow-lg font-semibold w-full"
                  icon="icon-filter"
                  icon-pack="feather"
                  type="border"
                  :color="filterSelected === 'completed' ? 'danger' : 'success'"
                  @click="selectFilter('completed')"
                >
                  {{ filterSelected === 'completed' ? 'Disable Filter' : 'Enable Filter' }}
                </vs-button>
              </div>
            </div>
          </vx-card>
          <vx-card class="mb-3">
            <div class="term-filter-container">
              <div class="flex flex-row gap-x-3">
                <div class="vx-col flex items-center justify-center">
                  <feather-icon :icon="'XCircleIcon'" svg-classes="h-12 w-12 text-warning" />
                </div>
                <div class="vx-col flex flex-col justify-center">
                  <h4 class="mb-1 font-semibold">Remaining Terms</h4>
                  <h6 class="font-light">{{ progressSummary.termsRemaining }} Terms</h6>
                </div>
              </div>

              <div class="term-filter-button vx-col justify-center" v-if="progressSummary.termsRemaining > 0">
                <vs-button
                  class="shadow-lg font-semibold w-full"
                  icon="icon-filter"
                  icon-pack="feather"
                  type="border"
                  :color="filterSelected === 'remaining' ? 'danger' : 'success'"
                  @click="selectFilter('remaining')"
                >
                  {{ filterSelected === 'remaining' ? 'Disable Filter' : 'Enable Filter' }}
                </vs-button>
              </div>
            </div>
          </vx-card> -->
          <vx-card>
            <div class="term-filter-container">
              <div class="flex flex-row gap-x-3">
                <div class="vx-col flex items-center justify-center">
                  <feather-icon :icon="'FlagIcon'" svg-classes="h-12 w-12 text-danger" />
                </div>
                <div class="vx-col flex flex-col justify-center">
                  <h4 class="mb-1 font-semibold">Flagged Terms</h4>
                  <h6 class="font-light">{{ progressSummary.termsFlagged }} Terms</h6>
                </div>
              </div>

              <div class="term-filter-button vx-col justify-center" v-if="progressSummary.termsFlagged > 0">
                <vs-button
                  class="shadow-lg font-semibold w-full"
                  icon="icon-filter"
                  icon-pack="feather"
                  type="border"
                  :color="filterSelected === 'flagged' ? 'danger' : 'success'"
                  @click="selectFilter('flagged')"
                >
                  {{ filterSelected === 'flagged' ? 'Disable Filter' : 'Enable Filter' }}
                </vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-popup>
      <vs-popup :active.sync="searchTermPopup" title="Search for Term">
        <vs-table :data="setData.terms" max-items="5" pagination search>
          <template slot="header">
            <div class="pt-3 pb-3 w-full">
              <div class="flex items-center justify-between">
                <h3 class="ml-4">Terms</h3>
              </div>
            </div>
          </template>

          <template slot="thead">
            <vs-th>Term</vs-th>
            <vs-th class="term-definition-header">Definition</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="attach.term" class="w-2/5">
                <div class="flex items-center">
                  <feather-icon
                    v-if="progressData.termsCompleted.includes(attach.id)"
                    :icon="'CheckCircleIcon'"
                    svg-classes="h-6 w-6 mr-2 text-success"
                  />
                  <feather-icon v-else :icon="'XCircleIcon'" svg-classes="h-6 w-6 mr-2 text-danger"></feather-icon>
                  <feather-icon
                    v-if="progressData.termsFlagged.includes(attach.id)"
                    :icon="'FlagIcon'"
                    svg-classes="h-6 w-6 mr-2 text-warning"
                  />
                  {{ attach.term.substring(0, 55) }}{{ attach.term.length > 55 ? '...' : '' }}
                </div>
              </vs-td>

              <vs-td :data="attach.definition" class="w-2/5 term-definition">
                {{ attach.definition.substring(0, 55) }}{{ attach.definition.length > 55 ? '...' : '' }}
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" icon-pack="feather" icon="icon-eye" @click="jumpToTermBySearch(attach.id)">
                  View
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <vs-popup :active.sync="settingsPopup" class="pb-3" title="FlashCard Settings">
        <div class="py-3 px-3">
          <div class="flex p-3 rounded-lg mb-5 vx-card">
            <vs-switch color="success" v-model="settings.keyboardShortcuts" class="mr-3" />
            <h4>Keyboard Shortcuts</h4>
          </div>
          <div class="flex p-3 rounded-lg mb-5 vx-card">
            <vs-switch color="success" v-model="settings.lottieAnimation" class="mr-3" />
            <h4>Lottie Animations</h4>
          </div>
          <div class="p-3 rounded-lg mb-5 vx-card">
            <h4 class="mb-3">Default Card Side</h4>
            <div class="flex items-center">
              <vs-radio v-model="settings.defaultCardSide" vs-value="term" vs-name="defaultCardSide" class="mr-3">Term</vs-radio>
              <vs-radio v-model="settings.defaultCardSide" vs-value="definition" vs-name="defaultCardSide">Definition</vs-radio>
            </div>
          </div>
          <div class="p-3 rounded-lg vx-card">
            <h4 class="mb-3">Default Card Color</h4>
            <div class="flex items-center flex-wrap gap-3">
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="0, 89, 255"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #0059ff"
                >Blue</vs-radio
              >
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="16, 172, 132"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #10ac84"
                >Green</vs-radio
              >
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="253, 68, 69"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #fd4445"
                >Red</vs-radio
              >
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="255, 159, 67"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #ff9f43"
                >Orange</vs-radio
              >
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="99, 39, 205"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #5f27cd"
                >Purple</vs-radio
              >
              <vs-radio
                v-model="settings.defaultCardColor"
                color="white"
                vs-value="243, 104, 224"
                vs-name="defaultCardColor"
                class="p-2 font-bold rounded-lg"
                style="background: #f368e0"
                >Pink</vs-radio
              >
            </div>
          </div>
        </div>
      </vs-popup>
      <vs-popup :active.sync="setCompletePopup" class="pb-3" title="Set Complete">
        <div>
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/success.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">Set Complete</h1>
          <h3 class="text-center font-light mb-3">Keep Up The Good Work!</h3>
          <h3 class="text-center font-light mb-3">
            You Have Completed All The Terms<br />
            In The {{ setData.name }} Set
          </h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="success" @click="setCompletePopup = false">Continue</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
    <div v-else class="simple-spinner">
      <span></span>
    </div>
  </div>
</template>

<script>
import FlashCardView from './components/FlashCardView';
import FlashCardAdmin from './components/FlashCardAdmin';
import RadialProgressBar from 'vue-radial-progress';
import analyticsData from '../../../../components/analyticsData';

export default {
  data() {
    return {
      courseId: null,
      domainId: null,
      setId: null,

      setData: null,
      setTermIndex: 0,
      setTermId: null,

      progressData: null,
      progressSummary: {
        termsCount: 0,
        termsRemaining: 0,
        termsCompleted: 0,
        termsFlagged: 0,
        currentTerm: 0,
        percent: 0,
      },

      setCompletePopup: false,

      jumpTermPopup: false,
      jumpTermValue: '',

      searchTermPopup: false,

      filterPopup: false,
      filterSelected: null,

      checkButton: false,
      finishedLesson: false,

      settingsPopup: false,
      settings: {
        defaultCardSide: 'term',
        defaultCardColor: '0, 89, 255',
        randomizeColors: false,
        lottieAnimation: true,
        keyboardShortcuts: true,
      },

      showCard: false,
      enableCardButtons: true,
      enableFlagButton: true,
      cardAnimationActive: false,

      enableEditMode: false,

      intervalId: null,
      termTimeout: null,

      vueAppRunner: `${analyticsData.createFunc}../node_modules/webpack/buildin/amd-options.js`,
    };
  },
  components: {
    FlashCardView,
    FlashCardAdmin,
    RadialProgressBar,
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    currentTerm() {
      return this.activeTerms[this.setTermIndex];
    },
    activeTerms() {
      const attempt = this.progressData;

      return this.setData.terms.filter((term) => {
        if ((this.filterSelected === 'completed' || this.filterSelected === null) && attempt.termsCompleted.includes(term.id)) {
          return true;
        }
        if ((this.filterSelected === 'flagged' || this.filterSelected === null) && attempt.termsFlagged.includes(term.id)) {
          return true;
        }
        if ((this.filterSelected === 'remaining' || this.filterSelected === null) && !attempt.termsCompleted.includes(term.id)) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    settings: {
      handler() {
        localStorage.setItem('flashCardSettings', JSON.stringify(this.settings));
      },
      deep: true,
    },
    setTermIndex() {
      this.setTermId = this.currentTerm.id;
      clearTimeout(this.termTimeout);
      this.termTimeout = setTimeout(() => {
        this.logTerm(this.currentTerm.id);
      }, 2500);
    },
  },
  methods: {
    startNextTerm() {
      this.stopAction(); // Ensure no intervals are already running
      // Initial delay of 500ms before starting the interval
      this.intervalId = setTimeout(() => {
        this.intervalId = setInterval(this.nextTerm, 50); // Continue with 125ms intervals
      }, 410);
    },
    startPreviousTerm() {
      this.stopAction(); // Ensure no intervals are already running
      // Initial delay of 500ms before starting the interval
      this.intervalId = setTimeout(() => {
        this.intervalId = setInterval(this.previousTerm, 50); // Continue with 125ms intervals
      }, 410);
    },
    stopAction() {
      // Clear both timeout and interval to ensure we stop the initial delay and the continuous action
      clearTimeout(this.intervalId);
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    alertInfo(title, msg) {
      this.$vs.notify({
        color: 'primary',
        title,
        text: msg,
        icon: 'feather',
        iconPack: 'icon-info',
      });
    },
    cardTransition() {
      if (this.cardAnimationActive === true) return false;
      this.cardAnimationActive = true;
      this.showCard = false;
      setTimeout(() => {
        this.showCard = true;
        this.cardAnimationActive = false;
      }, 49);
    },
    completeTerm(termId) {
      this.$http
        .post('study/flashcard/completeTerm', {
          domainId: this.domainId,
          setId: this.setId,
          courseId: this.courseId,
          termId,
        })
        .then((response) => {
          if (response.status === 200) {
            const run = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.vueAppRunner).toString(this.CryptoJS.enc.Utf8));
            this.progressData = run.attempt;
            this.progressSummary = run.progress;
          }
        })
        .catch(() => {});
    },
    flippedCard() {
      if (!this.progressData.termsCompleted.includes(this.setTermId)) {
        this.completeTerm(this.setTermId);
      }
    },
    fetchStartDetails() {
      this.$http
        .post('study/flashcard/start', {
          domainId: this.domainId,
          setId: this.setId,
          courseId: this.courseId,
        })
        .then((response) => {
          if (response.status === 200) {
            const run = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.vueAppRunner).toString(this.CryptoJS.enc.Utf8));

            this.setData = run.set;
            this.progressData = run.attempt;
            this.progressSummary = run.progress;

            this.setTermIndex = run.attempt.currentTermIndex;
            this.setTermId = this.currentTerm.id;

            if (this.activeTerms.length === 0) {
              this.$vs.notify({
                title: 'Error While Loading FlashCards',
                text: 'There are no terms in the set you are trying to practice',
                icon: 'feather',
                iconPack: 'icon-x-circle',
                color: 'danger',
                position: 'top-right',
              });
              return this.$router.push('/study/select');
            }
          }
        })
        .catch(() => {
          return this.$router.push('/study/select');
        });
    },
    jumpToTermByNumber() {
      if (this.errors.has('Jump')) return;
      this.cardTransition();
      this.timeoutCardButton();
      this.setTermIndex = this.jumpTermValue - 1;
      this.jumpTermValue = null;
      this.jumpTermPopup = false;
    },
    jumpToTermBySearch(id) {
      const index = this.activeTerms.findIndex((term) => term.id === id);
      if (index <= -1) return;
      this.cardTransition();
      this.timeoutCardButton();
      this.setTermIndex = index;
      this.searchTermPopup = false;
    },
    keyPressed(e) {
      if (this.settings.keyboardShortcuts && !this.searchTermPopup && !this.jumpTermPopup && !this.filterPopup && !this.enableEditMode) {
        if (e.code === 'ArrowRight' || e.code === 'Enter' || e.key.toLowerCase() === 'd') {
          this.nextTerm();
        }
        if (e.code === 'ArrowLeft' || e.key.toLowerCase() === 'a') {
          this.previousTerm();
        }
        if (
          e.code === 'Space' ||
          e.code === 'ArrowUp' ||
          e.code === 'ArrowDown' ||
          e.key.toLowerCase() === 's' ||
          e.key.toLowerCase() === 'w'
        ) {
          this.$refs.flashCardView.flipCard();
        }
      }
    },
    logTerm(termId) {
      this.$http
        .post('study/flashcard/logTerm', {
          domainId: this.domainId,
          setId: this.setId,
          courseId: this.courseId,
          termId,
        })
        .then((response) => {
          if (response.status === 200) {
            const run = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.vueAppRunner).toString(this.CryptoJS.enc.Utf8));

            this.progressData = run.attempt;
            this.progressSummary = run.progress;
          }
        })
        .catch(() => {});
    },
    nextTerm() {
      if (!this.enableCardButtons) return;
      if (this.setTermIndex + 1 >= this.activeTerms.length) {
        // return this.alertInfo('Reached The End Of The Set', 'Make Sure To Go Through All Terms Without Skipping To Complete The Set');
        return false;
      }
      this.cardTransition();
      this.timeoutCardButton();
      this.setTermIndex++;
    },
    previousTerm() {
      if (!this.enableCardButtons) return;
      if (this.setTermIndex <= 0) {
        // return this.alertInfo('Reached The Beginning Of The Set', 'Make Sure To Go Through All Terms Without Skipping To Complete The Set');
        return false;
      }
      this.cardTransition();
      this.timeoutCardButton();
      this.setTermIndex--;
    },
    timeoutCardButton() {
      this.enableCardButtons = false;
      setTimeout(() => {
        this.enableCardButtons = true;
      }, 100);
    },
    toggleFlagged(termId) {
      if (!this.enableFlagButton) return;
      this.enableFlagButton = false;
      this.$http
        .post('study/flashcard/toggleFlagTerm', {
          domainId: this.domainId,
          setId: this.setId,
          courseId: this.courseId,
          termId,
        })
        .then((response) => {
          if (response.status === 200) {
            const run = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.vueAppRunner).toString(this.CryptoJS.enc.Utf8));

            this.enableFlagButton = true;
            this.progressData = run.attempt;
            this.progressSummary = run.progress;
            if (this.filterSelected === 'flagged' && this.progressSummary.termsFlagged === 0) {
              this.filterSelected = null;
            }
            if (this.filterSelected === 'completed' && this.progressSummary.termsCompleted === 0) {
              this.filterSelected = null;
            }
            if (this.filterSelected === 'remaining' && this.progressSummary.termsRemaining === 0) {
              this.filterSelected = null;
            }
          }
        })
        .catch(() => {});
    },
    setComplete() {
      this.$confetti.start({});

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);

      this.flashCardCompleteSound.play();
      this.setCompletePopup = true;
    },
    selectFilter(filter) {
      const validFilters = ['completed', 'remaining', 'flagged'];
      if (!validFilters.includes(filter)) return;
      if (filter === this.filterSelected) filter = null;
      this.filterSelected = filter;
      this.setTermIndex = 0;
    },
  },
  mounted() {
    this.flashCardCompleteSound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Success.mp3');
    window.flashCardCompleteSound = this.flashCardCompleteSound;

    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    if (localStorage.getItem('flashCardSettings')) {
      const settings = JSON.parse(localStorage.getItem('flashCardSettings'));
      Object.keys(this.settings).forEach((key) => {
        if (settings[key]) this.settings[key] = settings[key];
      });
    }

    this.courseId = this.activeCourseInfo.id;
    this.domainId = this.$route.query.domain;
    this.setId = this.$route.params.setId;

    this.cardTransition();
    this.fetchStartDetails();
    window.addEventListener('keydown', this.keyPressed);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
    window.removeEventListener('keydown', this.keyPressed);
  },
};
</script>

<style lang="scss">
#FcRenderer {
  cursor: url('~@/assets/images/cursor/cursor-auto.png'), auto;
  padding-top: 2.85rem;

  button {
    cursor: url('~@/assets/images/cursor/cursor-pointer.png'), auto;
  }

  .cursor-pointer {
    cursor: url('~@/assets/images/cursor/cursor-pointer.png'), auto !important;
  }

  .mobile-navigation {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
  }

  .term-nav-container {
    justify-content: center;
  }

  .term-count {
    margin: 0rem 2rem 0rem 2rem;
    font-size: 24px;
  }

  .FcAppScreen {
    min-height: 75vh;
    padding: 3rem;
  }

  .FcAppParent {
    max-width: 1250px;
    width: 100%;
    height: 100%;
    font-size: 32px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
  }

  .FcAppContainer {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }

  .fcTheArrow {
    z-index: 99;
  }

  .fcCard {
    width: 100%;
    height: 100%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    max-width: 850px;
  }

  @media (min-width: 300px) and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    .term-nav-container {
      justify-content: space-between !important;
    }

    .term-count {
      margin: 0rem !important;
      font-size: 20px !important;
    }

    .term-filter-container {
      display: flex;
      flex-direction: column !important;
      justify-content: space-between;
    }
  }

  @media (min-width: 750px) and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    .term-nav-container {
      justify-content: center !important;
    }

    .term-count {
      margin: 0rem 2rem 0rem 2rem !important;
      font-size: 24px !important;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    .FcAppScreen {
      width: 100% !important;
      padding: 0rem;
      align-items: flex-start !important;
      min-height: auto !important;
      margin-bottom: 1rem;
    }

    .FcAppContainer {
      flex-direction: column;
      padding: 0rem 1rem 0rem 1rem;
    }

    .info-container {
      width: 100% !important;
    }

    .fcTheArrow {
      display: none;
    }

    .fcRightArrow {
      display: none;
    }

    .set-name-desktop {
      display: none;
    }

    .set-name-mobile {
      display: flex !important;
      margin-bottom: 2rem !important;
    }

    .vs-con-loading__container {
      width: 100% !important;
    }

    .set-name-text-mobile {
      font-size: 22px !important;
    }

    .set-name-terms-mobile {
      font-size: 20px !important;
    }

    .set-progress-card {
      margin-top: 3rem !important;
    }
  }

  @media (min-width: 1001px) and (max-width: 1400px) {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;

    .FcAppScreen {
      width: 100% !important;
      padding: 0rem;
    }

    .FcAppContainer {
      flex-direction: column;
      padding: 0rem 1rem 0rem 1rem;
    }

    .info-container {
      width: 100% !important;
    }

    .set-name-desktop {
      display: none;
    }

    .set-name-medium {
      display: flex !important;
      margin-bottom: 2rem !important;
    }

    .set-name-text-mobile {
      font-size: 22px !important;
    }

    .set-name-terms-mobile {
      font-size: 20px !important;
    }

    .set-progress-card {
      align-items: center;
      display: flex;
    }

    .mobile-navigation {
      display: flex;
      margin-bottom: 3rem;
      margin-top: 1.4rem;
    }

    .vs-con-loading__container {
      width: 100%;
    }

    .progress-text-desktop {
      display: none !important;
    }

    .progress-text-medium {
      display: block !important;
      width: 100% !important;
      margin-bottom: 0.8rem;
    }

    .bottom-content {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }
  }
}

.term-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.term-filter-button {
  display: flex;
  height: fit-content;
}

@media (min-width: 300px) and (max-width: 450px) {
  .term-filter-container {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    row-gap: 1rem;
    align-items: flex-start;
  }

  .term-filter-button {
    display: flex;
    align-items: flex-end;
  }

  .term-definition {
    display: none;
  }

  .term-definition-header {
    display: none !important;
  }
}
</style>
