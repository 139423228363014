<template>
  <div class="flex items-center justify-center">
    <div class="card cursor-pointer" @click="flipCard" ref="flashcard" v-hammer:swipe.left="onSwipeLeft" v-hammer:swipe.right="onSwipeRight">
      <div :class="isToggle ? 'activeTransitionFront' : ''" class="card__front card__part " :style="`background: rgba(${cardColor}, 0.4); border: 2px solid rgba(${cardColor}, 1)`">
        <div class="flex flex-col justify-center">
          <div v-if="frontImage" class="">
            <div class="front-card-container gap-4">
              <div class="flex flex-col items-center justify-center">
                <fit-text class="text-center break-words card__text" v-if="!isToggle">{{ card.textFront }}</fit-text>
              </div>
              <div class="flex flex-col items-center justify-center">
                <lottie-animation v-if="card.imgFront.endsWith('.json') && !isToggle" class="mobile-lottie" :path="card.imgFront" :loop="true" :autoPlay="true" :speed="1"/>
                <img v-else :src="card.imgFront" width="210" class="rounded-lg" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col items-center justify-center">
              <fit-text class="text-center break-words card__text">{{ card.textFront }}</fit-text>
            </div>
          </div>
        </div>
        <div class="card__flag" v-if="flaggedTerm">
          <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 text-white"/>
        </div>
        <div class="card__state">
          <p v-if="!isToggle">Term</p>
          <p v-if="isToggle">Definition</p>
        </div>
      </div>
      <div :class="isToggle ? 'activeTransitionBack' : ''" class="card__back card__part " :style="`background: rgba(${cardColor}, 0.4); border: 2px solid rgba(${cardColor}, 1)`">
        <div class="flex flex-col justify-center">
          <div v-if="backImage">
            <div class="grid gap-4" style="grid-template-columns: 2fr 1fr;">
              <div class="flex flex-col items-center justify-center">
                <fit-text class="text-center break-words card__text pr-3" v-if="isToggle">{{ card.textBack }}</fit-text>
              </div>
                <div class="flex flex-col items-center justify-center">
                  <lottie-animation v-if="card.imgBack.endsWith('.json')" :path="card.imgBack" :loop="true" :autoPlay="true" :speed="1"/>
                  <img v-else :src="card.imgBack" width="210" class="rounded-lg" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
                </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col items-center justify-center">
              <fit-text class="text-center break-words card__text" v-if="isToggle">{{ card.textBack }}</fit-text>
            </div>
          </div>
          <div class="card__flag" v-if="flaggedTerm">
            <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 text-white"/>
          </div>
          <div class="card__state">
            <p v-if="!isToggle">Term</p>
            <p v-if="isToggle">Definition</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FitText from './FitText'
import LottieAnimation from '../../../../../components/lottie/LottieAnimation'
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

export default {
  data () {
    return {
      card: {
        textFront: '',
        textBack: '',
        imgFront: '',
        imgBack: ''
      },
      isToggle: false
    }
  },
  directives: {
    viewer: viewer()
  },
  computed: {
    frontImage () {
      if (!this.card.imgFront) return false
      if (this.card.imgFront.endsWith('.json') && !this.lottieAnimation) return false
      if (this.card.imgFront) return true
    },
    backImage () {
      if (!this.card.imgBack) return false
      if (this.card.imgBack.endsWith('.json') && !this.lottieAnimation) return false
      if (this.card.imgBack) return true
    }
  },
  methods: {
    flipCard () {
      this.isToggle = !this.isToggle
      this.$emit('flipped')
    },
    onSwipeLeft() {
      this.$emit('swipe-right');
    },
    onSwipeRight() {
      this.$emit('swipe-left');
    },
  },
  beforeDestroy () {
    this.card = {
      textFront: '',
      textBack: '',
      imgFront: '',
      imgBack: ''
    }
  },
  beforeMount () {
    if (this.defaultSide === 'term') {
      this.card.textFront = this.textFront
      this.card.textBack = this.textBack
      this.card.imgFront = this.imgFront
      this.card.imgBack = this.imgBack
    } else {
      this.card.textFront = this.textBack
      this.card.textBack = this.textFront
      this.card.imgFront = this.imgBack
      this.card.imgBack = this.imgFront
    }
  },
  mounted () {
    const flashcard = this.$refs.flashcard;
    const mc = new Hammer(flashcard);

    mc.on('swipeleft', () => {
      this.onSwipeLeft();
    });

    mc.on('swiperight', () => {
      this.onSwipeRight();
    });
  },
  components: {
    FitText,
    LottieAnimation
  },
  props: {
    textFront: {
      type: String,
      default: ''
    },
    textBack: {
      type: String,
      default: ''
    },
    imgFront: {
      type: String,
      default: null
    },
    imgBack: {
      type: String,
      default: null
    },
    cardColor: {
      type: String,
      default: '#0059ff'
    },
    defaultSide: {
      type: String,
      default: 'term'
    },
    flaggedTerm: {
      type: Boolean,
      default: false
    },
    lottieAnimation: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>

@media (max-width: 750px) {
  .card {
    width: 100%!important;
  }

  .front-card-container {
    display: grid;
    grid-template-columns: none!important;
  }

  .mobile-lottie {
    width: 50%!important;
  }
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

.card {
  width: 95%;
  height: 475px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective:600px;
}

.card__state {
  display: none;
  font-weight: 300;
  font-size: 17px;
  position: absolute;
  top: 13px;
  left: 15px;
}

.card__flag {
  position: absolute;
  display: flex;
  bottom: 17px;
  left: 15px;
}

.card__text {
  max-height: 380px;
  overflow: auto;
}

.card__part{
  top: 0;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.front-card-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.card__front{
  padding: 28px;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  transform-style: flat;
}

.card__back {
  padding: 28px;
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  transform-style: flat;
}

.activeTransitionFront {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  transform-style: flat;
}

.activeTransitionBack {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform-style: flat;
}
</style>
