<template>
  <div class="flex items-center justify-center">

    <div class="card cursor-pointer">
      <div :class="isToggle ? 'activeTransitionFront' : ''" class="card__front card__part " :style="`background: ${cardColor}`">
        <div class="w-full h-full flex flex-col justify-center w-full">
          <div class="mb-1">
            <div class="grid gap-4" style="grid-template-columns: 2fr 1fr;">
              <div class="flex flex-col  justify-center">
                <h4 class="font-bold mb-1">Term</h4>
                <vs-textarea height="185px" class="mb-4 w-full" v-model="editData.term" size="large"/>
              </div>
              <div class="flex flex-col items-center justify-center" v-if="editData.term_image">
                <lottie-animation v-if="editData.term_image.endsWith('.json')" :path="editData.term_image" :loop="true" :autoPlay="true" :speed="1" class="w-3/4"/>
                <img v-else :src="editData.term_image" width="190" class="rounded-lg" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
              </div>
            </div>
          </div>
          <h4 class="font-bold mb-1">Term Image URL</h4>
          <vs-input v-model="editData.term_image" class="w-full mb-4" />
          <div class="grid grid-cols-2 gap-4">
            <vs-button type="gradient" color="primary" class="" @click="flipCard">Flip To Term</vs-button>
            <vs-button type="gradient" color="success" class="" @click="saveChanges">Save Changes</vs-button>
          </div>
        </div>
        <div class="card__flag" v-if="flaggedTerm">
          <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 text-white"/>
        </div>
      </div>

      <div :class="isToggle ? 'activeTransitionBack' : ''" class="card__back card__part " :style="`background: ${cardColor}`">
        <div class="w-full h-full flex flex-col justify-center">
          <div class="mb-1">
            <div class="grid gap-4" style="grid-template-columns: 2fr 1fr;">
              <div class="flex flex-col  justify-center">
                <h4 class="font-bold mb-1">Definition</h4>
                <vs-textarea height="185px" class="mb-4 w-full" v-model="editData.definition" size="large"/>
              </div>
              <div class="flex flex-col items-center justify-center" v-if="editData.definition_image">
                <lottie-animation v-if="editData.definition_image.endsWith('.json')" :path="editData.definition_image" :loop="true" :autoPlay="true" :speed="1" class="w-3/4" />
                <img v-else :src="editData.definition_image" width="190" class="rounded-lg" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
              </div>
            </div>
          </div>
          <h4 class="font-bold mb-1">Definition Image URL</h4>
          <vs-input v-model="editData.definition_image" class="w-full mb-4" />
          <div class="grid grid-cols-2 gap-4">
            <vs-button type="gradient" color="primary" class="" @click="flipCard">Flip To Term</vs-button>
            <vs-button type="gradient" color="success" class="" @click="saveChanges">Save Changes</vs-button>
          </div>
          <div class="card__flag" v-if="flaggedTerm">
            <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 text-white"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import FitText from './FitText'
import LottieAnimation from '../../../../../components/lottie/LottieAnimation'
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

export default {
  data () {
    return {
      isToggle: false,
      editData: {}
    }
  },
  directives: {
    viewer: viewer({
      debug: false
    })
  },
  methods: {
    flipCard () {
      this.isToggle = !this.isToggle
      this.$emit('flipped')
    },
    saveChanges () {
      this.$vs.loading()
      this.$http.post(`flashcards/terms/${this.editData.id}`, this.editData)
        .then(response => {
          if (response.status === 200) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Updated Term Successfully',
              text: 'Term has been updated.',
              color: 'success',
              position: 'top-right'
            })
          }
        })
        .catch(error => {
          this.$vs.loading.close()
          return this.$vs.notify({
            title: 'Failed to update term',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.editData = this.termData
  },
  components: {
    FitText,
    LottieAnimation
  },
  props: {
    cardColor: {
      type: String,
      default: '#0059ff'
    },
    termData: {
      type: Object
    },
    flaggedTerm: {
      type: Boolean,
      default: null
    }
  }
}
</script>
<style scoped>

::-webkit-scrollbar-track {
  border-radius: 5px;
}

.card{
  width: 95%;
  height: 475px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective:600px;
}

.card__flag {
  position: absolute;
  display: flex;
  bottom: 17px;
  left: 15px;
}

.card__part{
  top: 0;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.card__front{
  padding: 28px;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  transform-style: flat;
}

.card__back {
  padding: 28px;
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  transform-style: flat;
}

.activeTransitionFront {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  transform-style: flat;
}

.activeTransitionBack {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform-style: flat;
}
</style>
